import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { trailStatisticShape } from '../../../shapes/entities';

const TrailStatisticMessage = ({
  scoreClassName,
  scoreColor,
  trailStatistic
}) => {
  const style = scoreColor ? { color: scoreColor } : {};
  const highestScore = (
    <span className={scoreClassName} {...{ style }}>
      {trailStatistic.attributes.highest_score}
    </span>
  );

  const meanScore = (
    <span className={scoreClassName} {...{ style }}>
      <FormattedNumber
        value={trailStatistic.attributes.mean_score}
        maximumFractionDigits={1}
      />
    </span>
  );

  return (
    <FormattedMessage
      id="runs.results.trail_statistic_message"
      values={{ highest_score: highestScore, mean_score: meanScore }}
    />
  );
};

TrailStatisticMessage.defaultProps = {
  scoreColor: null,
  scoreClassName: ''
};

TrailStatisticMessage.propTypes = {
  scoreClassName: PropTypes.string,
  scoreColor: PropTypes.string,
  trailStatistic: trailStatisticShape.isRequired
};

export default TrailStatisticMessage;
