import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import QuestionStatisticsCard from './question_statistics_card';
import RunRankingCard from './run_ranking_card';
import MascotCard from './mascot_card';
import RecentRunsCard from './recent_runs_card';
import SubTestsStatisticsCard from './sub_tests_statistics_card';

import Controls from '../../controls';
import ControlItem from '../../control_item';
import {
  runShape,
  trailShape,
  subTestShape,
  spiderGraphShape,
  runStatisticShape,
  trailStatisticShape,
  scoringResultsShape
} from '../../../shapes/entities';
import * as alignments from '../../controls/alignments';

const getTrailType = run => {
  if (run.attributes.type === 'RaceTrail') return 'race';
  if (run.attributes.purpose === 'simulation') return 'simulation';

  return 'training';
};

const getColorProps = (subTest, trail, trailType) => {
  if (trailType === 'simulation') {
    return { scoreClassName: 't-color--test' };
  }

  return {
    scoreColor:
      (subTest && subTest.attributes.color) || (trail && trail.attributes.color)
  };
};

const getRightCard = (
  run,
  runStatistic,
  trailStatistic,
  runStatisticsByTrail,
  runSubTestStatistics,
  scoreColor,
  trailType
) => {
  if (trailType === 'race' && runStatistic) {
    return (
      <MascotCard run={run} showTitle={false} runStatistic={runStatistic} />
    );
  }

  if (trailType === 'training' && trailStatistic && runStatisticsByTrail) {
    const { scoreColor: color } = scoreColor;
    return (
      <RecentRunsCard
        run={run}
        runStatistics={runStatisticsByTrail}
        trailStatistic={trailStatistic}
        scoreColor={color}
      />
    );
  }

  if (trailType === 'simulation' && trailStatistic && runSubTestStatistics) {
    const { scoreClassName } = scoreColor;
    return (
      <SubTestsStatisticsCard
        run={run}
        trailStatistic={trailStatistic}
        subTestsStatistics={runSubTestStatistics}
        scoreClassName={scoreClassName}
      />
    );
  }

  return null;
};

const ScreenTitle = ({ run, subTest }) => {
  const { name: runName, purpose: runPurpose } = run.attributes;

  if (run.attributes.type === 'RaceTrail') return runName;

  if (run.attributes.compendium_content)
    return run.attributes.type === 'ExerciseTrail' ? runPurpose : runName;

  const runType =
    run.attributes.type === 'ExerciseTrail' ? runPurpose : 'lesson';

  const runDisplayName =
    runType === 'lesson' || runPurpose === 'simulation' || !subTest
      ? runName
      : `${subTest.attributes.name} - ${runName}`;

  return (
    <FormattedMessage
      id={`runs.results.run_types.${runType}`}
      values={{ name: runDisplayName }}
    />
  );
};

ScreenTitle.propTypes = {
  run: runShape.isRequired,
  subTest: subTestShape
};

ScreenTitle.defaultProps = {
  subTest: null
};

const ResultsScreen = ({
  run,
  trail,
  subTest,
  runStatistic,
  scoringResults,
  trailStatistic,
  onNavigateSolutions,
  runStatisticsByTrail,
  runSubTestStatistics
}) => {
  const trailType = getTrailType(run);
  const scoreColor = getColorProps(subTest, trail, trailType);

  return (
    <div className="qa-results-screen">
      <h1>
        <span className="t-margin-x-small--right">
          <FormattedMessage id="runs.results.title" />:
        </span>

        <ScreenTitle run={run} subTest={subTest} />
      </h1>

      <ul className="l-grid l-grid--3-col l-flex-grid l-flex-grid--3-col@large">
        <li className="l-grid__item l-width--4-of-12@large t-margin-large--bottom">
          <QuestionStatisticsCard
            onClick={onNavigateSolutions}
            scoringResults={scoringResults}
            {...scoreColor}
          />
        </li>
        <li className="l-grid__item l-width--4-of-12@large t-margin-large--bottom">
          {runStatistic && (
            <RunRankingCard
              run={run}
              runStatistic={runStatistic}
              {...scoreColor}
            />
          )}
        </li>
        <li className="l-grid__item l-width--4-of-12@large t-margin-large--bottom">
          {getRightCard(
            run,
            runStatistic,
            trailStatistic,
            runStatisticsByTrail,
            runSubTestStatistics,
            scoreColor,
            trailType
          )}
        </li>
      </ul>

      <Controls align={alignments.end}>
        <ControlItem>
          <a
            className="o-button o-button--test-color l-width--16-of-16@small-only t-margin--bottom@small-only"
            href={`/runs/${run.id}/return`}
          >
            <FormattedMessage id="runs.show.to_startpage" />
          </a>
        </ControlItem>
      </Controls>
    </div>
  );
};

ResultsScreen.defaultProps = {
  trail: null,
  subTest: null,
  runStatistic: null,
  trailStatistic: null,
  runSubTestStatistics: null,
  runStatisticsByTrail: null
};

ResultsScreen.propTypes = {
  run: runShape.isRequired,
  subTest: subTestShape,
  onNavigateSolutions: PropTypes.func.isRequired,
  runStatistic: runStatisticShape,
  runStatisticsByTrail: PropTypes.arrayOf(runStatisticShape),
  trail: trailShape,
  trailStatistic: trailStatisticShape,
  scoringResults: scoringResultsShape.isRequired,
  runSubTestStatistics: spiderGraphShape
};

export default ResultsScreen;
