import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BarChart from '../../../../bar_chart';
import EvaluationLink from './evaluation_link';
import TrailStatisticMessage from './trail_statistic_message';

import {
  runShape,
  runStatisticShape,
  trailStatisticShape
} from '../../../shapes/entities';

const RecentRunsCard = ({ run, runStatistics, trailStatistic, scoreColor }) => {
  // runStatistics come in limited and ordered by id ascending.
  const statisticsForBarChart = runStatistics.map(runStatistic => ({
    runId: runStatistic.attributes.run_id,
    highlight: run.id === runStatistic.attributes.run_id.toString(),
    userScore: runStatistic.attributes.user_score,
    achievableScore: runStatistic.attributes.achievable_score
  }));

  return (
    <article className="l-grid__item c-results-card c-results-card--last qa-recent-runs-card">
      <div className="l-flex-grid__intro l-flex-grid__intro-ranking">
        <header>
          <FormattedMessage id="runs.results.recent_runs_header">
            {text => <h2 className="o-h2 t-text-weight--bold">{text}</h2>}
          </FormattedMessage>
        </header>
        <BarChart
          config={{ color: scoreColor }}
          statistics={statisticsForBarChart}
        />
        {run.attributes.type !== 'ExerciseTrail' && (
          <div className="t-padding--top">
            <p className="t-no-margin t-line-height--tight t-text-align--center qa-trail-statistic-message">
              <TrailStatisticMessage
                scoreColor={scoreColor}
                trailStatistic={trailStatistic}
              />
            </p>
          </div>
        )}
        <div className="l-flex-grid__end">
          <EvaluationLink run={run} />
        </div>
      </div>
    </article>
  );
};

RecentRunsCard.propTypes = {
  run: runShape.isRequired,
  runStatistics: PropTypes.arrayOf(runStatisticShape).isRequired,
  trailStatistic: trailStatisticShape.isRequired,
  scoreColor: PropTypes.string.isRequired
};

export default RecentRunsCard;
